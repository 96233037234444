:root {
  --brown: #8B4513;
  --green: #1e7e34;
  --light-green: #00AB28;
  --white: #fff;
}

/************************************ START: APP.JS **********************************************/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}
/************************************ END: APP.JS ************************************************/
/************************************ START: FOOTER.JS *******************************************/
.footer-container {
  flex-shrink: 0;
  margin-top: auto;

  background-color: #242424;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: center;
  width: 160px;
  box-sizing: border-box;
  align-items: center;
}

.footer-links a {
  color: var(--white);
  text-decoration: none;
  margin-bottom: 0.5rem;
}

div.footer-container > a {
  color: var(--white);
  text-decoration: none;
  margin-bottom: 0.5rem;
  margin-top: 0px;
  align-items: center;
}

.footer-links a:hover {
  color: #C0C0C0;
  transition: 0.3s ease-out;
}

div.footer-container > a:hover {
  color: #C0C0C0;
  transition: 0.3s ease-out;
}

.modal-body.footer {
  display: flex;
}

#footer-email {
  text-indent: 8px;
  color: var(--brown);
}

@media screen and (max-width: 500px) {
  .footer-container {
    font-size: 1.0rem;
  }
}

@media screen and (max-width: 450px) {
  .footer-container {
    font-size: 0.8rem;
  }
}
/************************************ END: FOOTER.JS *********************************************/
/************************************ START: ABOUTUS.JS ******************************************/
.info-container {
  background-color: #ffffff73;
  background-position: center;
  background-size: cover;
}

.info-message {
  font-weight: bold;
  padding: 10px;
}

.info-container > h1 {
  text-align: center;
  color: black;
}

.info-message > p {
  color: var(--brown);
  text-indent: 40px;
  font-size: 20px;
}

#info-subtitle {
  color: black;
  font-size: 26px;
  margin-bottom: 0px;
  text-indent: 0px;
}

#img-credit {
  text-decoration: none;
}

@media screen and (max-width: 450px) {
  .info-container {
    margin-top: 20%;
  }
}
/************************************ END: ABOUTUS.JS ********************************************/
/************************************ START: FAQ.JS **********************************************/
.faq-message {
  padding: 10px;
}

.info-bulletin {
  font-weight: bold;
  font-size: 20px;
}

.faq-message > p {
  color: var(--brown);
  text-indent: 40px;
  font-size: 17px;
}
/************************************ END: FAQ.JS ************************************************/
/************************************ START: BUTTON.JS *******************************************/
:root {
  --primary: var(--white);
}

.btn {
  padding: .375rem .75rem;
  border-radius: .25rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  color: var(--white);
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

a.btn-mobile > button {
  background-color: transparent;
  color: var(--white);
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  font-size: 1.2rem;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 20px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
  background: var(--white);
  color: #242424;
  transition: all 0.3s ease-out;
}
/************************************ END: BUTTON.JS *********************************************/
/************************************ START: LANDINGPAGE.JS **************************************/
.landing-container {
  background: url('./images/cafe.jpg') center center/cover no-repeat;
  color: var(--white);

  position: fixed;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.landing-page-message {
  background-color: #ffffff73;
  background-position: center;
  background-size: cover;
  text-align: left;
  
  margin: 10vh 100px 0px 100px;

  font-weight: bold;
  padding: 10px;
  border-radius: 20px;
}

#call-to-action {
  text-align: left;
  color: var(--brown);
  font-size: 70px;
}

.call-to-action-msg {
  color: var(--brown);
  font-size: 32px;
}

@media screen and (max-width: 960px) {
  #call-to-action {
    font-size: 40px;
}

.call-to-action-msg {
    font-size: 30px;
}
}

@media screen and (max-width: 768px) {
  #call-to-action {
      font-size: 35px;
  }

  .call-to-action-msg {
      font-size: 25px;
  }
}

@media screen and (max-width: 600px) {
  #call-to-action {
      font-size: 30px;
  }

  .call-to-action-msg {
      font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .landing-page-message {
    margin: 10vh 50px 0px 50px;
  }

  #call-to-action {
      font-size: 25px;
  }

  .call-to-action-msg {
      font-size: 15px;
  }
}
/************************************ END: LANDINGPAGE.JS ****************************************/
/************************************ START: NAVBAR.JS *******************************************/
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

#root > nav {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  z-index: 999;
}

.navbar-logo {
  color: var(--white);
  justify-self: start;
  margin-left: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 55vw;
  justify-content: end;
  margin-right: 2rem;
  margin-top: 25px;
}

.nav-menu-logged-in {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 75vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 60px;
}

.nav-links {
  color: var(--white);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid var(--white);
  transition: all 0.2s ease-out;
  color: var(--white);
  text-decoration: none;
}

.nav-user-links {
  height: 80px;
  width: 100%;
  color: var(--white);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  height: 100%;
}

ul > li:nth-child(2) > a {
  text-decoration: none;
  color: var(--white);
  margin-right: min(30px, 0);
}

ul > li:nth-child(2) > a:hover {
  text-decoration: none;
}

.navbar-logo:hover {
  color: var(--white);
  text-decoration: none;
}

.fa-bars {
  color: var(--white);
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu-logged-in {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 999;
    margin-top: 0px;
    margin: 0;
    padding: 0;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-user-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: var(--white);
    color: #242424;
    border-radius: 0;
    text-decoration: none;
  }

  .navbar-logo {
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(5%, 50%);
  }

  .navbar-logo:hover {
    color: var(--white);
    text-decoration: none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--white);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: var(--white);
    padding: 14px 20px;
    border: 1px solid var(--white);
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: var(--white);
    color: #242424;
    transition: 250ms;
  }
}
/***************************************** END: NAVBAR.JS *********************************************/
/***************************************** START: CREATEREMINDER.JS ***********************************/
.createreminder-container {
  padding: 10px;
}

.inputTextBoxDateFormat {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
}

#createreminder-new-btn {
  font-size: 20px;
  background: var(--green);
  
  display: grid;  
}

#createreminder-new-btn:hover {
  background: var(--light-green);
  transition: 0.3s ease-out;
}

.form-control.reminder-details {
  width: 100%;
  height: 150px;
}

#create-reminder-due-date,
#create-reminder-reminder-date,
.form-control.date-picker {
  background-color: var(--white) !important;
}
/***************************************** END: CREATEREMINDER.JS ********************************/
/***************************************** START: REMINDERS.JS ***********************************/
.reminders-container {
  height: 100%;
  width: auto;
  text-align: center;
  overflow-x: hidden;
}

#reminders-table-row {
  text-align: center;
}

.main-dashboard-btns {
  display: grid;
  padding: 20px;
  justify-content: right;
}

.dashboard-btns {
  display: flex;
}

.reminder-tabs {
  justify-content: center;
  text-align: center;
  font-weight: bold;
  color: var(--brown);
  font-size: large;
}

.reminder-titles {
  font-size: 24px;
  overflow-wrap: break-word;
}

#overdue-title {
  color: red;
  font-size: 24px;
}

.reminders-headers {
  font-size: 20px;
  color: var(--brown);
}

.table { 
  table-layout: fixed; 
} 

.no-data-available-msg {
  font-size: 18px;
}

.search-btn-on-dashboard {
  text-decoration: none;
  font-size: 29px;
  color: var(--white);
  background: var(--green);
  border-radius: 4px;

  padding: 0px 8px 0px 8px;
  margin: 10px 0px 15px 0;
}

.search-btn-on-dashboard:hover {
  color: var(--white);
  background: var(--light-green);
  transition: 0.3s ease-out;
}
/***************************************** END: REMINDERS.JS *************************************/
/***************************************** START: EDITREMINDER.JS ********************************/
.edit-reminder-container {
  text-align: left;
}

.edit-reminder-btn {
  text-align: center;
}

.btn.btn-info {
  padding: 8px 15px;
}

.editreminder-completed {
  text-align: left;
  margin-bottom: 20px;
}

.editreminder-completed > label {
  margin-right: 10px;
  font-size: 24px;
  color:  var(--brown);
  font-weight: bold;
}

.completed-checkboxes {
  width: 18px;
  height: 18px;
}
/***************************************** END: EDITREMINDER.JS **********************************/
/***************************************** START: LOGIN.JS ***************************************/
.form-label, 
.form-label.reminder-details,
.form-label.update-profile,
.form-label.update-profile.pNum {
  font-size: 1.25em;
  color: var(--brown);
  display: block;
  opacity: 1;
  transform: translateY(-1.75em);
  transform-origin: 0 0;
  transition: all 0.5s;
  text-indent: 10px;
}

.form-label.reminder-details {
  transform: translateY(-7.3em);
}

.form-group {
  margin-bottom: -10px;
  width: 100%;
}

.form-group.reminders {
  width: 100%;
  margin-top: 10px;
  margin-bottom: -10px;
}

.form-group.profile {
  margin-top: 15px;
}

.form-group.change-pwd {
  margin-top: 10px;
}

div.form-group.reminders > div {
  width: 100%;
}


/* Moving the input field label above. */
.form-control:focus + .form-label,
.form-control:not(:placeholder-shown) + .form-label {
  transform:translateY(-3.3em) scale(0.8);
}

.cellphone-child-left:focus + .form-label,
.cellphone-child-left:not(:placeholder-shown) + .form-label {
  transform:translateY(-3.3em) scale(0.8);
}

.form-control:focus + .form-label.reminder-details,
.form-control:not(:placeholder-shown) + .form-label.reminder-details {
  transform:translateY(-8.9em) scale(0.8);
}

.form-control:focus + .form-label.update-profile,
.form-control:not(:placeholder-shown) + .form-label.update-profile {
  transform:translateY(-3.2em) scale(0.8);
}

.form-control:focus + .form-label.update-profile.pNum,
.form-control:not(:placeholder-shown) + .form-label.update-profile.pNum {
  transform:translateY(-3.3em) scale(0.8);
}

div.form-group.reminders > div + .form-label {
  transform:translateY(-3.3em) scale(0.8);
}

#create-reminder-details {
  width: 100%;
  height: 150px;
}


@media screen and (max-width: 960px) {
  .form-label {
    font-size: 1em;
    transform: translateY(-2em);
  }

  .form-control:focus + .form-label,
  .form-control:not(:placeholder-shown) + .form-label {
    transform:translateY(-3.7em) scale(0.8);
  }

  .form-group.reminders {
    margin-bottom: 10px;
  }

  div.form-group.reminders > div + .form-label {
    transform:translateY(-4em) scale(0.8);
  }

  .cellphone-child-left:focus + .form-label.signup.cell-phone-carrier,
  .cellphone-child-left:not(:placeholder-shown) + .form-label.signup.cell-phone-carrier {
    transform:translateY(-3.9em);
  }

  .form-control:focus + .form-label.signup.pNum,
  .form-control:not(:placeholder-shown) + .form-label.signup.pNum {
    transform:translateY(-3.9em);
  }
}

@media screen and (max-width: 300px) {
  .form-label.reminder-details {
    font-size: 1em;
    transform: translateY(-9.1em);
  }

  .form-control:focus + .form-label.reminder-details,
  .form-control:not(:placeholder-shown) + .form-label.reminder-details {
    transform:translateY(-11em) scale(0.8);
  }
}
/***************************************** END: LOGIN.JS *****************************************/
/***************************************** START: SIGNUP.JS ************************************/
#cellphone-container {
  display: flex;
}

.cellphone-child-left {
  width: 100%;
}

.form-control.cellphone-child {
  width: 100%;
}

@media screen and (max-width: 600px) {
  #cellphone-container {
    display: block;
  }

  .form-group.signup.cell-phone-carrier {
    margin-bottom: 0px;
  }
}

.cell-phone-fieldset {
  margin-bottom: 30px;
  padding: 0.35em 0.75em 0.75em 0.625em;
  border: 3px groove;
  border-radius: .25rem;

  display: block;
}

.cell-phone-legend {
  width: auto;
  padding: 0 10px 0 10px; 

  transform:translateY(-1em);
}

.name-label {
  display: block;
}

legend { 
  background-color: var(--white);
  border-radius: .25rem;
}

@media screen and (max-width: 500px) {
  .cell-phone-legend {
    font-size: 20px;
  }
}
/***************************************** END: SIGNUP.JS **************************************/
/***************************************** START: DROPDOWN.JS and MENUITEMS.JS *******************/
.drop-down-menu {
  width: 250px;
  list-style: none;
  
  position: fixed;
  right: 0px;
}

.drop-down-menu li {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  cursor: pointer;
}

.drop-down-menu.clicked {
  display: none;
}

.drop-down-link {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--white);
  padding: 16px;

  text-align: center;
  justify-content: center;
  justify-items: center;
}

.drop-down-link:hover {
  text-decoration: none;
  color: #C0C0C0;
  transition: 0.3s ease-out;
}

@media screen and (max-width: 960px) {
  .drop-down-menu {
    display: flex;
    flex-direction: column;
    position: start;
    top: 240px;
    opacity: 1;
    left: 0%;
    text-align: center;
    margin: 0 auto;
  }

  .drop-down-link {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .drop-down-link:hover {
    background-color: var(--white);
    color: #242424;
    border-radius: 0;
    text-decoration: none;
  }
}
/***************************************** END: DROPDOWN.JS and MENUITEMS.JS *********************/
/***************************************** START: MANAGEPROFILE.JS *******************************/
.manage-profile-container > h1 {
  text-align: center;
  margin: 20px 0 20px 0;
}

.manage-profile-headers {
  font-size: 20px;
  color: var(--brown);
}

#manage-profile-actions,
.manage-profile-btns {
  text-align: center;
}

.manage-profile-options {
  font-size: 24px;
  overflow-wrap: break-word;
}

#manage-profile-options-delete {
  color: red;
}

.btn.btn-info.manage-profile.change {
  font-size: 1rem;
}

.btn.btn-info.manage-profile.view {
  font-size: 1rem;
  width: 80px;
}

.btn.btn-danger.manage-profile {
  font-size: 1rem;
}

.modal.update-profile,
.modal.change-pwd,
.modal.change-general-reminder-time {
  text-align: left;
}
/***************************************** END: MANAGEPROFILE.JS *********************************/
/***************************************** START: CHANGEPASSWORD.JS ******************************/
#newPwd {
  margin-top: 30px;
}
/***************************************** END: CHANGEPASSWORD.JS ********************************/
/***************************************** START: CHANGEGENERALREMINDERTIME.JS *******************/
.info-message-grt > p {
  color: var(--brown);
  text-indent: 40px;
  font-size: 16px;
}

.general-reminder-time {
  transform: translate(32%, 0%);
}
/***************************************** END: CHANGEGENERALREMINDERTIMES.JS ********************/
/***************************************** START: SEARCH.JS **************************************/
.search-top-component {
  padding: 20px;
  margin: 20px 0px 20px 0px;
}

.form-control.search {
  font-size: 25px;
}

.search-btn-on-search {
  font-size: 35px;
  color: var(--white);
  background: var(--green);
  border-radius: 4px;

  padding: 0px 8px 0px 8px;
}

.search-btn-on-search:hover {
  background: var(--light-green);
  transition: 0.3s ease-out;
}
/***************************************** END: SEARCH.JS ****************************************/
/***************************************** START: FORGOTPWD.JS ***********************************/
.forgot-pwd.link {
  text-decoration: none;
  
  float: right;
  margin-bottom: 10px;
}

.forgot-pwd.message {
  color: var(--brown);
  font-size: 16px;
}

.form-group.forgot-pwd {
  margin-top: 30px;
}

.modal-body.forgot-pwd {
  text-align: left;
}
/***************************************** END: FORGOTPWD.JS *************************************/
/***************************************** START: PASSWORDTOGGLE.JS ******************************/
.pwd-toggle-icon {
  cursor: pointer;
  transition: all 0.5s;
  opacity: 1;
  display: flex;
  
  transform: translateY(-4.1em);
  float: right;
  margin-right: 5px;
  margin-bottom: -30px;
}
/***************************************** END: PASSWORDTOGGLE.JS ********************************/


/* MOBILE MODE FOR ALL FILES: */
@media screen and (max-width: 960px) {
  /* START: PASSWORDTOGGLE.JS */
  .pwd-toggle-icon {
    transform: translateY(-3.7em);
  }
  /* END: PASSWORDTOGGLE.JS */
}

@media screen and (max-width: 700px) {
  /* START: BUTTON.JS  */
  .btn.btn-info.edit-reminder,
  .btn.btn-danger.delete-reminder {
    font-size: 0.9rem;
  }
  /* END: BUTTON.JS  */

  /* START: CREATEREMINDER.JS */
  #createreminder-new-btn {
    font-size: 18px;
  }
  /* END: CREATEREMINDER.JS */

  /* START: MANAGEPROFILE.JS */
  .btn.btn-danger.manage-profile,
  .btn.btn-info.manage-profile.change {
    font-size: 0.9rem;
  }

  .btn.btn-info.manage-profile.view {
    font-size: 0.9rem;
    width: 77px;
  }

  .manage-profile-headers {
    font-size: 18px;
  }

  .manage-profile-options {
    font-size: 22px;
  }
  /* END: MANAGEPROFILE.JS */

  /* START: REMINDERS.JS */
  #overdue-title,
  .reminder-titles {
    font-size: 22px;
  }

  .no-data-available-msg {
    font-size: 16px;
  }

  .reminders-headers {
    font-size: 18px;
  }

  .search-btn-on-dashboard {
    font-size: 27px;
  }
  /* END: REMINDERS.JS */

  /* START: SEARCH.JS  */
  .form-control.search {
    font-size: 22px;
  }

  .search-btn-on-search {
    font-size: 30px;
  }
  /* END: SEARCH.JS  */
}

@media screen and (max-width: 600px) {
  /* START: BUTTON.JS  */
  .btn.btn-info.edit-reminder,
  .btn.btn-danger.delete-reminder {
    font-size: 0.85rem;
  }
  /* END: BUTTON.JS  */

  /* START: CREATEREMINDER.JS */
  #createreminder-new-btn {
    font-size: 16px;
  }
  /* END: CREATEREMINDER.JS */

  /* START: MANAGEPROFILE.JS */
  .btn.btn-danger.manage-profile,
  .btn.btn-info.manage-profile.change {
    font-size: 0.8rem;
  }

  .btn.btn-info.manage-profile.view {
    font-size: 0.8rem;
    width: 74px;
  }

  .manage-profile-headers {
    font-size: 16px;
  }

  .manage-profile-options {
    font-size: 20px;
  }
  /* END: MANAGEPROFILE.JS */

  /* START: REMINDERS.JS */
  #overdue-title,
  .reminder-titles {
    font-size: 20px;
  }

  .no-data-available-msg {
    font-size: 14px;
  }

  .reminders-headers {
    font-size: 16px;
  }

  .search-btn-on-dashboard {
    font-size: 25px;
  }
  /* END: REMINDERS.JS */

  /* START: SEARCH.JS  */
  .form-control.search {
    font-size: 20px;
  }

  .search-btn-on-search {
    font-size: 28px;
  }
  /* END: SEARCH.JS  */
}

@media screen and (max-width: 500px) {
  /* START: BUTTON.JS  */
  .btn.btn-info.edit-reminder,
  .btn.btn-danger.delete-reminder {
    font-size: 0.8rem;
  }
  /* END: BUTTON.JS  */

  /* START: CREATEREMINDER.JS */
  #createreminder-new-btn {
    font-size: 14px;
  }
  /* END: CREATEREMINDER.JS */

  /* START: MANAGEPROFILE.JS */
  .btn.btn-danger.manage-profile,
  .btn.btn-info.manage-profile.change {
    font-size: 0.7rem;
  }

  .btn.btn-info.manage-profile.view {
    font-size: 0.7rem;
    width: 68px;
  }

  .manage-profile-headers {
    font-size: 14px;
  }

  .manage-profile-options {
    font-size: 18px;
  }
  /* END: MANAGEPROFILE.JS */

  /* START: REMINDERS.JS */
  #overdue-title,
  .reminder-titles {
    font-size: 18px;
  }

  .no-data-available-msg {
    font-size: 12px;
  }

  .reminder-tabs {
    font-size: medium;
  }

  .reminders-headers {
    font-size: 14px;
  }

  .search-btn-on-dashboard {
    font-size: 23px;
  }
  /* END: REMINDERS.JS */

  /* START: SEARCH.JS  */
  .form-control.search {
    font-size: 18px;
  }

  .search-btn-on-search {
    font-size: 26px;
  }
  /* END: SEARCH.JS  */
}

@media screen and (max-width: 400px) {
  /* START: BUTTON.JS  */
  .btn.btn-info.edit-reminder,
  .btn.btn-danger.delete-reminder {
    font-size: 0.75rem;
  }
  /* END: BUTTON.JS  */

  /* START: CHANGEGENERALREMINDERTIME.JS */
  .general-reminder-time {
    transform: translate(28%, 0%);
  }
  /* END: CHANGEGENERALREMINDERTIME.JS */

  /* START: CREATEREMINDER.JS */
  #createreminder-new-btn {
    font-size: 12px;
  }
  /* END: CREATEREMINDER.JS */

  /* START: MANAGEPROFILE.JS */
  .manage-profile-headers {
    font-size: 12px;
  }

  .manage-profile-options {
    font-size: 16px;
  }
  /* END: MANAGEPROFILE.JS */

  /* START: REMINDERS.JS */
  #overdue-title,
  .reminder-titles {
    font-size: 16px;
  }

  .no-data-available-msg {
    font-size: 10px;
  }

  .reminder-tabs {
    font-size: small;
  }

  .reminders-headers {
    font-size: 12px;
  }

  .search-btn-on-dashboard {
    font-size: 21px;
  }
  /* END: REMINDERS.JS */

  /* START: SEARCH.JS  */
  .form-control.search {
    font-size: 16px;
  }

  .search-btn-on-search {
    font-size: 24px;
  }
  /* END: SEARCH.JS  */
}

@media screen and (max-width: 350px) {
  /* START: BUTTON.JS  */
  .btn.btn-info.edit-reminder,
  .btn.btn-danger.delete-reminder {
    font-size: 0.7rem;
  }
  /* END: BUTTON.JS  */

  /* START: CHANGEGENERALREMINDERTIME.JS */
  .general-reminder-time {
    transform: translate(24%, 0%);
  }
  /* END: CHANGEGENERALREMINDERTIME.JS */

  /* START: SEARCH.JS  */
  .form-control.search {
    font-size: 14px;
  }

  .search-btn-on-search {
    font-size: 22px;
  }
  /* END: SEARCH.JS  */
}

@media screen and (max-width: 300px) {
  /* START: BUTTON.JS  */
  .btn.btn-info.edit-reminder,
  .btn.btn-danger.delete-reminder {
    font-size: 0.65rem;
  }
  /* END: BUTTON.JS  */

  /* START: CHANGEGENERALREMINDERTIME.JS */
  .general-reminder-time {
    transform: translate(20%, 0%);
  }
  /* END: CHANGEGENERALREMINDERTIME.JS */

  /* START: CREATEREMINDER.JS */
  #createreminder-new-btn {
    font-size: 10px;
  }
  /* END: CREATEREMINDER.JS */

  /* START: REMINDERS.JS */
  .search-btn-on-dashboard {
    font-size: 19px;
  }
  /* END: REMINDERS.JS */

  /* START: SEARCH.JS  */
  .form-control.search {
    font-size: 12px;
  }

  .search-btn-on-search {
    font-size: 20px;
  }
  /* END: SEARCH.JS  */
}
